import React from "react"
import Row from "reactstrap/es/Row"
import Col from "reactstrap/es/Col"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import FeaturesHtmlSerializer from "../utils/FeaturesHtmlSerializer"

const FeatureLeft = ({ featureDetails }) => {
  return (
    <Row className="align-items-center">
      <Col lg="7">
        <div className="saas-feature-shape-left position-relative">
          {featureDetails.imageSrc !== null && <GatsbyImage
            image={featureDetails.imageSrc}
            alt={featureDetails.imageAlt}
            className="img-fluid mx-auto d-block rounded"/>}
        </div>
      </Col>
      <Col lg="5" className="mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div className="section-title ml-lg-4">
          <h2 className="title mb-3">{featureDetails.title}</h2>
          <RichText render={featureDetails.content} htmlSerializer={FeaturesHtmlSerializer}/>
        </div>
      </Col>
    </Row>
  )
}

FeatureLeft.propTypes = {
  featureDetails: PropTypes.object
}

export default FeatureLeft
