import React from "react"
import Row from "reactstrap/es/Row"
import Col from "reactstrap/es/Col"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import FeaturesHtmlSerializer from "../utils/FeaturesHtmlSerializer"

const FeatureRight = ({ featureDetails }) => {
  return (
    <Row className="align-items-center">
      <Col
        lg={{ size: 5, order: 1 }}
        xs={{ order: 2 }}
        className="mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div className="section-title mr-lg-4">
          <h2 className="title mb-3">{featureDetails.title}</h2>
          <RichText render={featureDetails.content} htmlSerializer={FeaturesHtmlSerializer}/>
        </div>
      </Col>

      <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
        <div className="saas-feature-shape-right position-relative">
          {featureDetails.imageSrc !== null && <GatsbyImage
            image={featureDetails.imageSrc}
            alt={featureDetails.imageAlt}
            className="img-fluid mx-auto d-block rounded"/>}
        </div>
      </Col>
    </Row>
  )
}

FeatureRight.propTypes = {
  featureDetails: PropTypes.object
}

export default FeatureRight
